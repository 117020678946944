import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { loginUrl, storeCurrentUrl } from '@shared/feature/auth';
import { UserService } from '@shared/util/user';

@Injectable({
  providedIn: 'root',
})
export class ReportAccessGuard {
  constructor(
    private userService: UserService,
    private router: Router,
  ) {}

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const isAuthenticated = this.userService.currentOrImpersonateUser.isAuthenticated;
    if (isAuthenticated) {
      return true;
    }
    // Store the userRequestUrl to return after the login is done!
    storeCurrentUrl(state.url);
    return this.router.parseUrl(loginUrl);
  }

  canActivateChild(activatedRouteSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    return this.canActivate(activatedRouteSnapshot, state);
  }
}
